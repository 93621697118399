import Api from 'src/apis/api'

class CalendarEventService {
  store () {
    const data = new FormData()
    data.append('business_name', clientAccount.business_name)
    // data.append('img_temp_file_id', brand.imgTempFileId)
    return Api.post('/api/warehouse-account/client-accounts', data)
  }

  search (start, end) {
    const data = new FormData()
    data.append('start', start)
    data.append('end', end)
    return Api.post('/api/user/calendar-events-search', data)
  }
}

export default new CalendarEventService()
