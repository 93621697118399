<template>
  <div class="container-fluid">
    <div class="row">
      <div class="col-md-10 ml-auto mr-auto">
        <div class="card card-calendar">
          <div class="card-body">
            <fullCalendar ref="calendar" :options="calendarOptions" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import FullCalendar from "@fullcalendar/vue";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";
import { INITIAL_EVENTS, createEventId } from "./event-utils";
import Swal from "sweetalert2";
import CalendarEventService from 'src/services/calendarEvent.service'

  export default {
    components: {
      FullCalendar
    },
    data() {
      return {
        calendarOptions: {
          plugins: [
            dayGridPlugin,
            timeGridPlugin,
            interactionPlugin, // needed for dateClick
          ],
          headerToolbar: {
            center: "dayGridMonth,timeGridWeek,timeGridDay",
            right: "prev,next,today",
          },
          initialView: "dayGridMonth",
          // initialEvents: INITIAL_EVENTS, // alternatively, use the `events` setting to fetch from a feed
          events: this.getEvents,
          editable: true,
          selectable: true,
          select: this.handleDateSelect,
          eventClick: this.handleEventClick,
          eventsSet: this.handleEvents
        },
        events: INITIAL_EVENTS
      }
    },
    methods: {
      init () {
        //console.log('init', this.events)
        // this.loadEvents()
      },
      async getEvents (info, successCallback, failureCallback) {
        // https://fullcalendar.io/docs/events-function
        console.log('getEvents', info)
        let newEvents = []
        let res = await CalendarEventService.search(info.startStr, info.endStr)
        console.log(res)
        let events = res.data
        for (var i = 0; i < events.length; i++) {
          let e = events[i]
          let class_name = "event-default"
          if (e.event_type === 'client_inbound_shipment_eta') class_name = 'event-red'
          let event = {
            id: e.id,
            title: e.title,
            start: new Date(e.start_time),
            allDay: e.all_day,
            className: class_name
          }
          if (e.end_time !== null) event.end = new Date(e.end_time)
          if (e.url !== null) event.url = e.url
          newEvents.push(event);
        }
        console.log('events', newEvents)
        successCallback(newEvents)
      },
      async loadEvents () {
        let res = await CalendarEventService.search()
        console.log(res)
        let events = res.data
        for (var i = 0; i < events.length; i++) {
          let e = events[i]
          let event = {
            id: e.id,
            tite: e.title,
            start: new Date(e.start_time),
            allDay: e.all_day,
            className: "event-default"
          }
          if (e.end_time !== null) event.end = new Date(e.end_time)
          if (e.url !== null) event.url = e.url
          this.events.push(event);
        }
        console.log('events', this.events)
        this.loaded = true
      },
      handleWeekendsToggle() {
        this.calendarOptions.weekends = !this.calendarOptions.weekends; // update a property
      },
      handleDateSelect(selectInfo) {
        // on select we show the Sweet Alert modal with an input
        const swalWithBootstrapButtons = Swal.mixin({
          customClass: {
            confirmButton: "btn btn-success",
            cancelButton: "btn btn-danger",
          },
          buttonsStyling: false,
        });
        swalWithBootstrapButtons
          .fire({
            title: "Create an Event",
            html: `<input type="text" id="md-input" class="form-control">`,
            showCancelButton: true,
          })
          .then(() => {
            var title = document.getElementById("md-input").value;
            let calendarApi = selectInfo.view.calendar;
            calendarApi.unselect(); // clear date selection
            if (title) {
              calendarApi.addEvent({
                id: createEventId(),
                title,
                start: selectInfo.startStr,
                end: selectInfo.endStr,
                allDay: selectInfo.allDay,
              });
            }
          });
      },
      handleEventClick(clickInfo) {
        if (
          confirm(
            `Are you sure you want to delete the event '${clickInfo.event.title}'`
          )
        ) {
          clickInfo.event.remove();
        }
      },
      handleEvents(events) {
        this.currentEvents = events;
      },
    },
    mounted: function () {
      this.$nextTick(function () {
        this.init()
      })
    }
  }
</script>
<style>
  #fullCalendar {
    min-height: 300px;
  }

  .el-loading-spinner .path {
    stroke: #66615B !important;
  }
</style>
